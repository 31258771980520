.newCustomerForm {
    /* border: 2px solid; */
    background: #F5DADA;
}

.newCustomerForm-head {
    color: #ED292D;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding-top: 30px;
    margin-bottom: 30px;
}

.customerDetails-Button {
    background: #ED292D;
    color: white;
    padding: 10px 15px;
    border-radius: 7px;
    border: none;
    margin-bottom: 30px;
}

.customerDetails-Button:hover {
    background: #c52225;
}