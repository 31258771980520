.single-chit-top {
    background: #D32728;
    width: 100%;
    height: 300px;
}

.single-chit-top-bck {
    position: relative;
}

.single-chit-top-contents {
    position: absolute;
    bottom: 0;
    top: 0;
    padding: 45px;
    color: white;
}

.single-chit-name {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 21px;
    display: flex;
    color: #D32728;
    justify-content: center !important;
    align-items: center !important;

}

.single-chit-id {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #D32728;
}

.single-chit-member {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #D32728;
}

.single-chit-button {
    background: #ED292D !important;
    color: white;
    /* width: 100%; */
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
}

.single-chit-address {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #D32728;
    text-align: center;
}

.single-chit-mrname {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #484848;
}

.single-chit-location {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.single-chit-head {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #484848;
}

.single-chit-amnt {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #D32728;
}

.single-chit-body {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #484848;
}

.document-head {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #D32728;
    text-align: center;
}