.solution-must {
    background: #FEF3F2;
    width: 100%;
    height: 230px;
}

.solution-top {
    background: #D32728;
    width: 98%;
    height: 230px;
    clip-path: polygon(0 0, 83% 0, 99.7% 100%, 0% 100%);
    position: relative;
}

.solution-top-one {
    background: #E88D8D;
    width: 100%;
    height: 230px;
    clip-path: polygon(0 0, 83% 0, 99.5% 100%, 0% 100%);
}

.solution-design {
    position: absolute;
    top: 0;
    left: 0;
}

.solution-head-content {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: white;
}

.solution-line {
    height: 5px;
    width: 110px;
    background: #FFD041;
}

.solution-head-desc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: white;
    margin-top: 20px;
}

.solution-contents {
    width: 80%;
    position: absolute;
    top: 50px;
    left: 17px;

}

.solution-row {
    --bs-gutter-x: 0rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.solution-left-clr {
    background: #FEF3F2;
    width: 100%;
    height: 230px;
    /* overflow: hidden; */
}

.solution-left-man {
    margin-left: auto;
    margin-top: -5px;
}

/* content */
.solutions-heading {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    text-align: center;
}

.solution-head-line {
    height: 5px;
    width: 110px;
    background: #D32728;

}

.solution-desc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-align: center;
}

.solution-desc-dark {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #D32728;
}


/* main contents */

.first-one-image {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.first-one-header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #111111;
}

.first-one-description {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #484848;
}

.logo1-image {
    height: 80px;
}

.first-one-subhead1 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #ED292D;
}

.first-one-subdesc1 {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #484848;
}

.fade-right {
    position: relative;
    height: 100%;
}

.ribbon-top-right {
    top: -3px;
    right: -3px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    left: -22px;
    top: 30px;
    transform: rotate(45deg);
}

.wdp-ribbon {
    display: inline-block;
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 15%;
    width: 50%;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em;
    border-radius: 5px;
    text-shadow: none;
    background-color: #ED292D !important;
    font-size: 22px;
    color: white;
    padding-top: 15px;
    cursor: pointer;
}

.wdp-ribbon2 {
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 15%;
    width: 50%;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em;
    border-radius: 5px;
    text-shadow: none;
    background-color: #ED292D !important;
    font-size: 22px;
    color: white;
    padding-top: 15px;
    cursor: pointer;
}

.solution-bredcrump {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
}