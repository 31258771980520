.chit-app-header {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: #000000;
}

.chit-app-secheade {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #000000;

}

.chit-app-secdesc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin-top: 20px;
}

.chit-app-leftone {
    margin-top: 50px;
}

.chit-app-system {
    padding: 15px;
}

.chit-app-line {
    height: 100px;
    width: 2px;
    background: #D32728;
    margin-top: -15px;
    margin-left: 65px;
}

.chit-app-lefttwo {
    margin-top: -15px;
}

.chit-app-leftthree {
    margin-top: -15px;
}

.chit-app-storesbt {
    border-color: #8C1A1A;
    color: #8C1A1A;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    background-color: #FEF3F2;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.chit-app-storesbt:hover {
    background: #ED292D;
    color: white;
    border-radius: 5px;
    border-color: #ED292D;
    font-family: "Inter", sans-serif;
    padding: 5px 20px 5px 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.chit-app-store {
    margin-top: -10px;
}