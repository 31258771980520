.myChit-name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.myChit-firstRow {
    justify-content: center;
    text-align: center;
}

.myChit-tabs {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 30px;
    border: 1px solid #D2D2D2;
    border-radius: 7px;
}

.nav-tabs {
    border: 1px solid #D2D2D2 !important;
}

.nav-tabs .nav-link.active {
    background: #ED292D !important;
    color: white !important;
    border: 1px solid #D2D2D2 !important;
}

.tab-name {
    color: black !important;
}