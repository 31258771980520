.chit-logo {
    height: 70px;
}

.navbar-collapse {
    flex-grow: 0.1 !important;
}

.topbar-list {
    border-bottom: 3px solid transparent !important;
}

.topbar-list:hover {
    color: #ED292D !important;
    border-bottom: 3px solid #ED292D !important;
}

.topbar-submit {
    background-color: #ED292D;
    padding-top: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.topbar-submit:hover {
    background-color: #c52225;
}

.navbar-nav {
    gap: 25px;
}

.topbar-menus {
    font-size: 20px;
    border-bottom: 3px solid transparent;

}

/* 
.topbar-menus:hover {
    font-size: 20px;
    border-bottom: 3px solid #ED292D;

} */

@media (max-width:1297px) {
    .topbar-menus {
        font-size: 16px !important;
    }

    .navbar-nav {
        gap: 15px;
    }

    .topbar-submit {
        padding-top: 12px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 12px;
    }

    .chit-logo {
        height: 50px;
    }
}

@media (max-width:1119px) {
    .topbar-menus {
        font-size: 14px !important;
    }

    .topbar-submit {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }
}

.user-dropdown {
    text-decoration: none;
}

.nav-top-drop {
    text-decoration: none;
    color: #484848;
    cursor: pointer;
}

.dropdown-toggle:hover {
    color: #ED292D !important;
}