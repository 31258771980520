.chit-cycle-head {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 38px;
    line-height: 45.99px;
}

.chit-cycle-line {
    height: 1px;
    background: #000000;
    width: 200px;
    margin: 13px 10px 0px 10px;
}

.cycle-chit-assume {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: #C74132;
}

.cycle-chit-desctext {
    border-radius: 41px;
    border: 1px solid #C74132;
    text-align: center;
    padding: 8px;
    color: #C74132;
    width: max-content;
    margin: 0 auto;
}

@media(max-width:992px) {
    .cycle-chit-desctext {
        border-radius: 41px;
        border: 1px solid #C74132;
        text-align: center;
        padding: 8px;
        color: #C74132;
        width: 100%;
        margin: 0 auto;
    }
}

.chit-cycle-bck {
    margin-top: 150px;
}

.cycle-chit-curve {
    width: 100%;
}

@media(max-width:991px) {
    .chit-cycle-upper {
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:991px) {
    .cycle-chit-curve {
        display: none;
    }
}

/* First image set */

.chit-cycle-first {
    position: relative;
    bottom: 100px;
    right: 20px;
    width: 20%;
}

@media(max-width:991px) {
    .chit-cycle-first {
        position: relative;
        bottom: 100px;
        width: 40%;
        right: 0px !important;
    }
}

.cycle-chit-book {
    height: 130px;
}

@media(max-width:767px) {
    .cycle-chit-book {
        height: 130px;
        margin-left: 60px !important;
    }
}

@media(max-width:991px) {
    .cycle-chit-book {
        height: 130px;
        margin-left: 100px;
    }
}

@media(max-width:802px) {
    .cycle-chit-book {
        height: 130px;
        margin-left: 65px;
    }
}

@media(max-width:546px) {
    .cycle-chit-book {
        height: 130px;
        margin-left: 25px !important;
    }
}

.cycle-chit-book-plan {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.89px;
    color: #ED292D;
    margin-left: 17px;
}

@media(max-width:991px) {
    .cycle-chit-book-plan {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 28px !important;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center;
        margin-left: 0px !important;
    }
}

@media(max-width:1551px) {
    .cycle-chit-book-plan {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 17px;
    }
}

@media(max-width:1439px) {
    .cycle-chit-book-plan {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 17px;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-plan {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 17px;
    }
}

.cycle-chit-book-desc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    margin-left: 17px;
}

@media(max-width:1551px) {
    .cycle-chit-book-desc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.36px;
        color: #000000;
        margin-left: 17px;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-desc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 19.36px;
        color: #000000;
        margin-left: 17px;
    }
}

@media(max-width:991px) {
    .cycle-chit-book-desc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
        width: 90%;
    }
}


/* second image */

.chit-cycle-second {
    position: relative;
    bottom: 180px;
    left: 280px;
    width: 25%;
}

@media(max-width:991px) {
    .chit-cycle-second {
        position: relative !important;
        bottom: 100px !important;
        width: 40% !important;
        left: 0px !important;
    }
}

@media(max-width:1627px) {
    .chit-cycle-second {
        position: relative;
        bottom: 180px;
        left: 180px;
        width: 25%;
    }
}

@media(max-width:1389px) {
    .chit-cycle-second {
        position: relative;
        bottom: 150px;
        left: 150px;
        width: 25%;
    }
}

.cycle-chit-hand {
    height: 130px;
    margin-left: 40px;
}

@media(max-width:546px) {
    .cycle-chit-hand {
        height: 130px;
        margin-left: 25px;
    }
}

@media(max-width:767px) {
    .cycle-chit-hand {
        height: 130px;
        margin-left: 60px !important;
    }
}

@media(max-width:991px) {
    .cycle-chit-hand {
        height: 130px;
        margin-left: 100px;
    }
}

@media(max-width:802px) {
    .cycle-chit-hand {
        height: 130px;
        margin-left: 65px;
    }
}

.cycle-chit-book-bid {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.89px;
    color: #ED292D;
    margin-left: 25px;
}

@media(max-width:1551px) {
    .cycle-chit-book-bid {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 25px;
    }
}

@media(max-width:1439px) {
    .cycle-chit-book-bid {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 25px;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-bid {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 33.89px;
        color: #ED292D;
        margin-left: 25px;
    }
}

@media(max-width:991px) {
    .cycle-chit-book-bid {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 28px !important;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center;
        margin-left: 0px;
    }
}

.cycle-chit-book-biddesc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    text-align: center;
}

@media(max-width:1551px) {
    .cycle-chit-book-biddesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-biddesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
    }
}

@media(max-width:991px) {
    .cycle-chit-book-biddesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 19.36px;
        color: #000000;
        text-align: center !important;
        width: 90% !important;
    }
}

/* third image */
.chit-cycle-third {
    position: relative;
    bottom: 60px;
    left: 150px;
    width: 25%;
}

@media(max-width:991px) {
    .chit-cycle-third {
        position: relative;
        bottom: 100px;
        width: 40% !important;
        left: 0px !important;
    }
}

@media(max-width:1627px) {
    .chit-cycle-third {
        position: relative;
        bottom: 60px;
        left: 70px;
        width: 25%;
    }
}

@media(max-width:1240px) {
    .chit-cycle-third {
        position: relative;
        bottom: 60px;
        left: 50px;
        width: 25%;
    }
}

@media(max-width:1120px) {
    .chit-cycle-third {
        position: relative;
        bottom: 60px;
        left: 40px;
        width: 25%;
    }
}

.cycle-chit-amnt {
    height: 130px;
    margin-left: 40px;
}

@media(max-width:767px) {
    .cycle-chit-amnt {
        height: 130px;
        margin-left: 60px !important;
    }
}

@media(max-width:546px) {
    .cycle-chit-amnt {
        height: 130px !important;
        margin-left: 25px !important;
    }
}

@media(max-width:802px) {
    .cycle-chit-amnt {
        height: 130px;
        margin-left: 65px !important;
    }
}

@media(max-width:1120px) {
    .cycle-chit-amnt {
        height: 130px;
        margin-left: 100px;
    }
}

.cycle-chit-book-inves {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.89px;
    color: #ED292D;
    text-align: center;
}

@media(max-width:991px) {
    .cycle-chit-book-inves {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 28px !important;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center !important;
    }
}

@media(max-width:1551px) {
    .cycle-chit-book-inves {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center;
    }
}

@media(max-width:1439px) {
    .cycle-chit-book-inves {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-inves {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center;
    }
}

.cycle-chit-book-invesdesc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    text-align: center;
}

@media(max-width:1551px) {
    .cycle-chit-book-invesdesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-invesdesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
    }
}

@media(max-width:991px) {
    .cycle-chit-book-invesdesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
        width: 90%;
    }
}

/* fourth image */
.chit-cycle-fourth {
    position: relative;
    bottom: 170px;
    left: 423px;
    width: 25%;
}

@media(max-width:991px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 60px !important;
        width: 40% !important;
        left: 0px !important;

    }
}

@media(max-width:1880px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 170px;
        left: 463px;
        width: 25%;
    }
}

@media(max-width:1740px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 170px;
        left: 433px;
        width: 25%;
    }
}

@media(max-width:1627px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 170px;
        left: 403px;
        width: 25%;
    }
}

@media(max-width:1530px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 170px;
        left: 363px;
        width: 25%;
    }
}

@media(max-width:1389px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 170px;
        left: 323px;
        width: 25%;
    }
}

@media(max-width:1240px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 140px;
        left: 283px;
        width: 25%;
    }
}

@media(max-width:1120px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 140px;
        left: 263px;
        width: 25%;
    }
}

@media(max-width:1015px) {
    .chit-cycle-fourth {
        position: relative;
        bottom: 140px;
        left: 230px;
        width: 25%;
    }
}

.cycle-chit-grow {
    height: 130px;
    margin-left: auto;
    display: flex;
    justify-content: end;
    align-items: end;
}

@media(max-width:767px) {
    .cycle-chit-grow {
        height: 130px;
        margin-left: 60px !important;
    }
}

@media(max-width:991px) {
    .cycle-chit-grow {
        height: 130px;
        margin-left: 100px;
    }
}

@media(max-width:802px) {
    .cycle-chit-grow {
        height: 130px;
        margin-left: 65px;
    }
}

.cycle-chit-book-save {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33.89px;
    color: #ED292D;
    text-align: end;
}

@media(max-width:991px) {
    .cycle-chit-book-save {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 28px !important;
        line-height: 33.89px;
        color: #ED292D;
        text-align: center !important;
    }
}

@media(max-width:1551px) {
    .cycle-chit-book-save {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: end;
    }
}

@media(max-width:1439px) {
    .cycle-chit-book-save {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: end;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-save {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 33.89px;
        color: #ED292D;
        text-align: end;
    }
}

.cycle-chit-book-savedesc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    text-align: end;
}

@media(max-width:1551px) {
    .cycle-chit-book-savedesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.36px;
        color: #000000;
        text-align: end;
    }
}

@media(max-width:1271px) {
    .cycle-chit-book-savedesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 19.36px;
        color: #000000;
        text-align: end;
    }
}

@media(max-width:991px) {
    .cycle-chit-book-savedesc {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 19.36px;
        color: #000000;
        text-align: center;
        width: 90%;
    }
}