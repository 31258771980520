.testimoni-head {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 38px;
    color: #000000;
}

.testimoni-desc {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #484848;
    margin-top: 15px;
}

.testimonial-name {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 28px;
    color: #D32728;
}

.testimonial-says {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    width: 85%;
}

@media(max-width:425px) {
    .testimonial-says {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        width: 100%;
    }
}


.testminial-card {
    height: 240px;
    border: 2px solid #D32728;
    border-radius: 8px;
    position: relative;
}

@media(max-width:1390px) {
    .testminial-card {
        height: 290px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:1170px) {
    .testminial-card {
        height: 310px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:1100px) {
    .testminial-card {
        height: 330px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:1038px) {
    .testminial-card {
        height: 380px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:872px) {
    .testminial-card {
        height: 440px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:806px) {
    .testminial-card {
        height: 480px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:767px) {
    .testminial-card {
        height: 330px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

@media(max-width:519px) {
    .testminial-card {
        height: 380px;
        border: 2px solid #D32728;
        border-radius: 8px;
        position: relative;
    }
}

.testmonial-quot {
    border: 1px solid #D32728;
    width: max-content;
    border-radius: 48%;
    color: #D32728;
    position: absolute;
    top: -25px;
    left: 40px;
}

.testi-line {
    height: 5px;
    width: 300px;
    background-color: #FFCF06;
    position: absolute;
    bottom: -35px;
    right: 0px;
    border-top-right-radius: 12px;
}

@media(max-width:1390px) {
    .testi-line {
        height: 5px;
        width: 300px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -42px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:1170px) {
    .testi-line {
        height: 5px;
        width: 300px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -46px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:1100px) {
    .testi-line {
        height: 5px;
        width: 300px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -49px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:1038px) {
    .testi-line {
        height: 5px;
        width: 300px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -55px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:960px) {
    .testi-line {
        height: 5px;
        width: 150px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -55px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:872px) {
    .testi-line {
        height: 5px;
        width: 150px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -61px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:806px) {
    .testi-line {
        height: 5px;
        width: 150px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -70px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:767px) {
    .testi-line {
        height: 5px;
        width: 300px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -49px;
        right: 0px;
        border-top-right-radius: 12px;
    }
}

@media(max-width:480px) {
    .testi-line {
        /* height: 5px;
        width: 200px;
        background-color: #FFCF06;
        position: absolute;
        bottom: -49px;
        right: 0px;
        border-top-right-radius: 12px; */
        display: none;
    }
}

.teatimonial-slide {
    padding: 0 15px;
    position: absolute;
    bottom: 15%;
    left: 0;
}

@media(max-width:1821px) {
    .teatimonial-slide {
        padding: 0 15px;
        position: absolute;
        bottom: 15%;
        left: 0;
    }
}

@media(max-width:1531px) {
    .teatimonial-slide {
        padding: 0 15px;
        position: absolute;
        top: -3%;
        left: 0;
    }
}

@media(max-width:1390px) {
    .teatimonial-slide {
        padding: 0 15px;
        position: absolute;
        top: 0%;
        left: 0;
    }
}


.testi {
    padding: 50px;
    margin-top: 40px;
}

@media(max-width:433px) {
    .testi {
        padding: 40px;
        margin-top: 0px;
        margin-bottom: 30px;
        margin-right: 20px;
    }
}

@media(max-width:425px) {
    .testi {
        padding: 40px;
        margin-top: 0px;
        margin-bottom: 90px;
        margin-right: -10px;
    }
}

@media(max-width:393px) {
    .testi {
        padding: 40px;
        margin-top: 0px;
        margin-bottom: 110px;
        margin-left: -30px;
    }
}

.testi-image {
    height: 100px !important;
    width: 100px !important;
    border: 2px solid #D32728;
    position: absolute;
    right: -62px;
}

@media(max-width:1390px) {
    .testi-image {
        height: 100px !important;
        width: 100px !important;
        border: 2px solid #D32728;
        position: absolute;
        bottom: -30px;
        /* right: -62px; */
    }
}

@media(max-width:425px) {
    .testi-image {
        height: 100px !important;
        width: 100px !important;
        border: 2px solid #D32728;
        position: absolute;
        bottom: -100px;
        right: 80px;
    }
}

@media(max-width:408px) {
    .testi-image {
        height: 100px !important;
        width: 100px !important;
        border: 2px solid #D32728;
        position: absolute;
        bottom: -100px;
        right: 60px;
    }
}

.slider2 {
    position: relative;
    justify-content: center;
}

.slider img {
    width: 100%;
    border-radius: 10px;
}