.why-choos-img {
    width: 100%;
}

.why-choos-head {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 32px;
}

.why-choos-desc {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
}

.why-choos-para {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.why-choos-check {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.why-choose-input {
    display: flex;
    justify-content: center;
}

.why-choos-submit {
    background-color: #ED292D;
    width: 400px;
    height: 54px;
    padding: 15px;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
}

.why-choose-number {
    position: relative;
}

@media(max-width:450px) {
    .why-choose-number {
        display: flex;
        justify-content: center;
        width: 200px;
    }
}

@media(max-width:450px) {
    .why-choos-submit {
        background-color: #ED292D;
        width: 200px;
        height: 54px;
        padding: 15px;
        color: white;
        border: none;
        border-radius: 5px;
        margin-top: 20px;
    }
}