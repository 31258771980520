.auth-modal {
    text-align: start;
    font-size: 32px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
}

.auth-modal-close {
    margin-left: auto;
    cursor: pointer;
    color: #484848;
    padding-top: 10px;
    transition: all .3s;
}

.auth-modal-close:hover {
    transform: scale(1.2);
    box-shadow: #000000;

}

.auth-modal-phonenumber {
    font-size: 18px;
    color: #484848;
    font-family: "Inter", sans-serif;
}


.number-icon {
    border: 1px solid grey;
    padding: 9px;
    color: grey;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.auth-modal-submitbn {
    background: #ED292D;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    margin-top: 30px;
}

.auth-modal-submitbn:hover {
    background-color: #c52225;
}

.auth-modal-line {
    width: 48%;
    height: 3px;
    background: #000000;
}

.auth-modal-or {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0px 5px 0px 5px;
}

.auth-facebook {
    border: 1px solid black;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.fb-content {
    font-size: 20px;
    margin-left: 15px;
}