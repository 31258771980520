.otp-send {
    text-align: start;
    font-size: 32px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
}

.otp-modal-close {
    margin-left: auto;
    cursor: pointer;
    color: #484848;
    padding-top: 10px;
    transition: all .3s;
}

.otp-modal-close:hover {
    transform: scale(1.2);
    box-shadow: #000000;
}

.otp-send-number {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: rgba(72, 72, 72, 1);
}

.otp-modal-edit {
    cursor: pointer;
}

.otp-modal-edit:hover {
    transform: scale(1.1);
    box-shadow: #000000;
}

.otp-modal-head {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: rgba(72, 72, 72, 1);
}

.custom-otp-field {
    padding: 10px;
    width: 4rem !important;
    text-align: center;
    border-radius: 8px;
    margin-left: 4px;
    justify-content: center;
    font-size: 24px;
}

.otp-resend-content {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.otp-resend {
    cursor: pointer;
}