.our-solution-head {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: rgba(0, 0, 0, 1);
}

.our-solution-line {
    height: 5px;
    width: 110px;
    background: rgba(211, 39, 40, 1);
}

.our-solution-para {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: rgba(72, 72, 72, 1);
}

.testimonialsCard {
    background-color: transparent !important;

}

.card-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20.86px;
    color: white !important;
}

.card-text {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 11.93px;
    color: white !important;
}

.our-solution-link {
    background: rgba(211, 39, 40, 1);
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.slider {
    position: relative;
    cursor: pointer;
    justify-content: center;
    overflow: hidden;
}

.slider img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
}

@media(max-width:460px) {
    .slider img {
        width: 100% !important;
        border-radius: 10px;
        height: 400px !important;
    }

    .our-solution-content {
        width: 87% !important;
        height: 130px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        bottom: 7px;
        left: 0;
        background: rgba(0, 0, 0, 0.463);
        margin: 0 8px;
    }
}

@media(max-width:1536px) {
    .slider img {
        width: 100%;
        border-radius: 10px;
        height: 370px;
    }

    .our-solution-content {
        width: 95% !important;
        height: 130px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        bottom: 7px;
        left: 0;
        background: rgba(0, 0, 0, 0.463);
        margin: 0 8px;
    }
}

@media(max-width:1280px) {
    .slider img {
        width: 100%;
        border-radius: 10px;
        height: 330px;
    }

    .our-solution-content {
        width: 93% !important;
        height: 130px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        bottom: 7px;
        left: 0;
        background: rgba(0, 0, 0, 0.463);
        margin: 0 8px;
    }
}

@media(max-width:1024px) {
    .slider img {
        width: 100%;
        border-radius: 10px;
        height: 250px;
    }

    .our-solution-content {
        width: 93% !important;
        height: 130px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        bottom: 7px;
        left: 0;
        background: rgba(0, 0, 0, 0.463);
        margin: 0 8px;
    }
}

@media(max-width:768px) {
    .slider img {
        width: 100%;
        border-radius: 10px;
        height: 100%;
    }

    .our-solution-content {
        width: 97% !important;
        height: 130px;
        padding: 8px 10px;
        border-radius: 10px;
        position: absolute;
        bottom: 7px;
        left: 0;
        background: rgba(0, 0, 0, 0.463);
        margin: 0 8px;
    }
}



.our-solution-content {
    width: 96% !important;
    height: 130px;
    padding: 8px 10px;
    border-radius: 10px;
    position: absolute;
    bottom: 7px;
    left: 0;
    background: rgba(0, 0, 0, 0.463);
    margin: 0 8px;
}