.footer {
    background: #D32728;
}

.footer-first-3 {
    padding-top: 20px;
}

.footer-logo {
    height: 80px;
    width: 80px;
}

.footer-logo-name {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    padding-top: 10px;

}

.footer-desc {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    padding-top: 30px;
}

.footer-lef-name {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    padding-top: 30px;
}

.footer-lef-desc {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
    cursor: pointer;
}

.footer-lef-desc1 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.footer-lef-arrow {
    color: #ffffff;
    margin-top: 30px;
    cursor: pointer;
}

.footer-lef-desc2 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.footer-lef-arrow2 {
    color: #ffffff;
    cursor: pointer;

}

.footer-lef-arrow1 {
    color: #ffffff;
    cursor: pointer;

}


.footer-right-name {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}

.footer-mail {
    color: #ffffff;
    margin-top: 30px;
}

.footer-mail1 {
    color: #ffffff;
}

.footer-mail-name {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}

.footer-mail-name1 {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
}

.footer-input-width {
    position: relative;
    /* width: 100%; */
}

.footer-submit {
    background-color: #ED292D;
    width: 100%;
    height: 54px;
    padding: 15px;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
}

.footer-submit:hover {
    background-color: #c52225;

}

.footer-input {
    background: white;
    padding: 10px;
}