.banner-content {
    font-size: 58px;
    font-weight: 500;
}

.banner-para {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
}

@media(max-width:1376px) {
    .banner-content {
        font-size: 54px;
        font-weight: 500;
    }

    .banner-para {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
    }
}

@media(max-width:1284px) {
    .banner-content {
        font-size: 49px;
        font-weight: 500;
    }

    .banner-para {
        font-weight: 300;
        font-size: 17px;
        line-height: 24px;
    }
}

@media(max-width:1167px) {
    .banner-content {
        font-size: 44px;
        font-weight: 500;
    }

    .banner-para {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
}

@media(max-width:1050px) {
    .banner-content {
        font-size: 40px;
        font-weight: 500;
    }

    .banner-para {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
    }
}

@media(max-width:957px) {
    .banner-content {
        font-size: 36px;
        font-weight: 500;
    }
}

@media(max-width:864px) {
    .banner-content {
        font-size: 32px;
        font-weight: 500;
    }
}

@media(max-width:767px) {
    .banner-content {
        font-size: 58px;
        font-weight: 500;
    }

    .banner-para {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
    }

}

/* .banner-cd-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.banner-submit {
    background-color: #ED292D;
    width: 150px;
    height: 54px;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    color: white;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.banner-submit:hover {
    background-color: #c52225;
}

.banner-card-content {
    font-weight: bold;
    font-size: 26px;
    margin: 5px 0px 0px 5px;
}

.banner-input-width {
    position: relative;
    width: 320px;
}

@media(max-width:1050px) {
    .banner-input-width {
        width: 200px;
    }
}

@media(max-width:767px) {
    .banner-input-width {
        width: 300px;
    }
}

@media(max-width:538px) {
    .banner-input-width {
        width: 200px;
    }
}

@media(max-width:420px) {
    .banner-input-width {
        width: 150px;
    }
}

@media(max-width:356px) {
    .banner-input-width {
        width: 100px;
    }
}

@media(max-width:575px) {
    .hero-media {
        padding-top: 50px !important;
    }
}