.myChit-Details {
    text-align: center;
}

.myChit-AmntHead {
    text-align: center;
    background: #F5DADA;
    padding: 8px;
    margin-top: 30px;
    border: 1px solid #D2D2D2;

}

.myChit-detail-calendar {
    height: 70px;
    margin-top: 40px;
}

.myChit-firstRow {
    margin-top: 25px;
}

.myChit-detail-leftSide {
    border: 1px solid #D2D2D2;
}

.prev-due {
    display: flex;
    padding: 5px;
    margin-top: 10px;
    border-bottom: 1px solid #D2D2D2;
}

.amount-payable-left {
    margin-left: auto;
    text-align: end;
}