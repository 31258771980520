.growthinvest-top-background {
    background: #D32728;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

.growthinvest-top-img {
    position: relative;
}

.growthinvest-top-contents {
    position: absolute;
    bottom: 0;
    top: 0;
    padding: 45px;
    color: white;
}

.growthinvest-top-left-bck {
    background: #D32728;
    width: 100%;
    height: 300px;
}

.growthinvest-top-left-img {
    width: 100%;
    height: 300px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 34% 100%);
}