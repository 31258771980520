.search-bar {
    position: relative;
    /* padding-left: 10px;
    padding-right: 10px; */
}

.search-icon {
    position: absolute;
    left: 15px;
    padding: 5px;
}

.search-input {
    background: #F6F6F6 !important;
    border-radius: 25px !important;
    padding: 9px !important;
}

.filter-group {
    /* border: 1px solid black;
    border-radius: 10px;
    width: max-content; */
    padding: 2px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-left: 10px;
}

.filter-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}

.filter-group-content {
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    width: max-content;
    padding: 4px;
}

.filter-group-content:hover {
    background-color: #ED292D;
    color: white;
    border: 1px solid #ED292D;
}

.allchit-screen {
    height: 50%;
}