.chit-section {
    background-image: url(../../assets/images/trendingChit/Group\ 1410110073.png);
}

.chit-head {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 32px;
}

.chit-head-line {
    height: 5px;
    width: 110px;
    background: rgba(211, 39, 40, 1);
}

.chit-card-button {
    background: #ED292D;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
}

.chit-card-button:hover {
    background-color: #c52225;
}

.chit-card-viewMore {
    border-color: #8C1A1A;
    color: #8C1A1A;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    background-color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.chit-card-viewMore:hover {
    background: #ED292D;
    color: white;
    border-radius: 5px;
    border-color: #ED292D;
    font-family: "Inter", sans-serif;
    padding: 5px 20px 5px 20px;
    font-weight: 500;
    font-size: 16px;
}

.trending-card-head {
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.trending-card-left-head {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.trending-card-rate {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.trending-card-left {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #484848;
}

.trending-card-right {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.based-plan-head {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 20px;
    align-items: center;
    color: #484848;
}

.goal-base-card {
    border-radius: 10px;
    box-shadow: 0px 18px 22px rgba(0, 0, 0, 0.1);
    /* Adjust the values as needed */

}

.goal-base-img {
    width: 100%;
    height: 300px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.goal-base-head {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20px;
    height: 50px;
}

.goal-base-desc {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}